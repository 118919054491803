.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.4rem;
  background: #ffffff;
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0px 0px 4px 1px #cccccc;
}

.userProfile {
  background-color: #35b736 !important;
  width: 40px;
  height: 40px;
  margin-left: 0 !important;
}
.userProfile svg {
  fill: #fff;
}

.headerWrapper ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.headerWrapper ul li a {
  color: #000000;
  text-decoration: none;
}

.headerWrapper ul li a:hover {
  color: #35b736;
}

.headerWrapper ul li {
  display: inline-block;
  margin-left: 30px;
  cursor: pointer;
  color: #000000;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.8px;
  transition: all 0.3s ease-in-out;
}

.headerWrapper ul li:hover {
  color: #35b736;
}

.headerWrapper ul li .active {
  color: #35b736;
}

.headerWrapper .siteLogo a {
  text-decoration: none;
  font-size: 35px;
  font-weight: 700;
  color: #35b736;
}

.headerWrapper .siteLogo svg {
  color: #000000;
  font-size: 35px;
}

.headerWrapper .siteLogo img {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
}

.headerWrapper .MobileBarNav {
  display: none;
}
.avatar {
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 22px;
  width: 40px;
  display: inline-block;
  cursor: pointer;
}

.avatarNotifWrapper {
  display: flex;
    align-items: center;
    margin-left: 24px;
}

@media (max-width: 599px) {
  .headerWrapper {
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    z-index: 105;
  }

  .headerWrapper .siteLogo a {
    font-size: 24px;
  }
}

@media (max-width: 599px) {
  .headerWrapper .MobileBarNav {
    display: block;
    position: relative;
    z-index: 3;
    font-size: 30px;
  }

  .headerWrapper .crossIcon {
    font-size: 30px;
    position: absolute;
    z-index: 110;
    right: 18px;
    top: 85px;
    color: #fff;
  }

  .headerWrapper .mobileNav {
    display: none;
  }
  .avatar {
    right: 18px;
    top: 22px;
    margin-left: 0;
    margin-bottom: 0;
  }

  .notificationsWrapper {
    top: 0;
    right: 8px;
  }
}

.headerWrapper .mobileNavOpen {
  display: block;
  position: fixed;
  top: 75px;
  left: 0;
  width: 100%;
  height: 100%;
  background: #35b736;
  z-index: 2;
}

.headerWrapper .mobileNavOpen ul {
  display: flex;
  align-items: center;
  min-height: 78vh;
  justify-content: center;
  flex-direction: column;
}

.headerWrapper .mobileNavOpen ul li {
  display: block;
  margin: 0 0 15px 0;
  color: #fff;
  font-size: 25px;
  font-weight: 400;
}

.headerWrapper .mobileNavOpen ul li a {
  color: #fff;
  font-size: 25px;
  font-weight: 400;
}

.loginWrapper {
  position: relative;
  /* display: flex; */
}

.loginRegisterNowBtn {
  color: #7e7e7e;
  border-top: 1px solid #eaeaea;
  padding: 20px 0 0 !important;
  text-align: center;
  margin-top: 30px;
}

.loginRegisterNowBtn span {
  color: #3682f5;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}

.loginWrapper .picture {
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  min-height: calc(100vh - 85px);
}

.loginWrapper .loginInner {
  padding: 30px;
}

.loginWrapper .loginInner .container {
  max-width: 600px;
  width: 100%;
  padding: 30px;
  background: #ffffff;
  margin: auto;
  box-sizing: border-box;
}

.loginWrapper .loginInner .container h1 {
  color: #35b736;
  text-align: center;
}

.loginWrapper .loginInner .container form {
  width: 75%;
  margin: auto;
}

.homeWrapper {
  position: relative;
}

.homeWrapper .picture {
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  min-height: calc(80vh - 106px);
  background-position: center;
}

@media (max-width: 599px) {
  .homeWrapper .picture {
    background-size: cover;
  }
}

.homeWrapper .picture .homeInner {
  min-height: calc(80vh - 106px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

@media (max-width: 599px) {
  .homeWrapper .picture .homeInner {
    flex-direction: column;
    align-items: center;
  }
  .homeWrapper .picture .homeInner > div {
    margin-top: 10px;
  }
}

.homeWrapper .picture .homeInner button {
  border: none;
  border-radius: 50px;
  background: #35b736;
  color: #ffffff;
  padding: 20px 60px;
  text-transform: uppercase;
  margin: 8px;
  font-size: 25px;
  cursor: pointer;
  font-weight: 700;
  letter-spacing: 1.8px;
}

@media (max-width: 599px) {
  .homeWrapper .picture .homeInner button {
    font-size: 24px;
  }
}

.findTravelerWrapper h1 {
  padding: 30px;
  margin: 0;
}

button {
  border-radius: 40px;
}

.formFieldsWrapper {
  position: relative;
  display: flex;
}

.formFieldsWrapper input,
.formFieldsWrapper select,
.formFieldsWrapper textarea {
  padding: 20px 20px;
  margin: 10px 0;
  width: 100%;
  box-sizing: border-box;
  box-sizing: border-box;
  background: #f4fef0;
  border: 1px solid #35b736;
  font-size: 20px;
  border-radius: 5px;
}

.formFieldsWrapper .errorMessage {
  color: red;
}

.formFieldsWrapper input:-webkit-autofill,
.formFieldsWrapper input:-webkit-autofill:hover,
.formFieldsWrapper input:-webkit-autofill:focus textarea:-webkit-autofill,
.formFieldsWrapper
  textarea:-webkit-autofill:hover
  textarea:-webkit-autofill:focus,
.formFieldsWrapper select:-webkit-autofill,
.formFieldsWrapper select:-webkit-autofill:hover,
.formFieldsWrapper select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #f4fef0 inset !important;
  -webkit-box-shadow: 0 0 0px 1000px #f4fef0 inset !important;
  border: 1px solid #35b736;
}

.btnWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 100px;
  justify-content: center;
}

.btnWrapper button {
  margin: 10px;
  border: none;
  padding: 12px 25px;
  background: #35b736;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0.8px;
  cursor: pointer;
}

.overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: 110;
}

.popupWrapper {
  position: relative;
  max-width: 560px;
  width: 100%;
  background: #ffffff;
  padding: 35px 0 30px 0;
  box-sizing: border-box;
  max-height: 520px;
  overflow: auto;
  box-shadow: 0px 0px 13px 1px #cccccc;
  border-radius: 5px;
}

.popupWrapper > h1,
.popupWrapper > div {
  padding: 0px 50px 0 50px;
}

@media (max-width: 599px) {
  .popupWrapper {
    max-height: inherit;
    height: 100vh;
    overflow-y: scroll;
  }
  .popupWrapper > h1,
  .popupWrapper > div {
    padding: 0 18px;
  }
}

.popupWrapper h1 {
  color: #35b736;
}
.popupWrapper h2 {
  color: #35b736;
  width: 100%;
  text-align: center;
}
.popupWrapper .popupClose {
  cursor: pointer;
  float: right;
  position: absolute;
  top: 15px;
  right: 15px;
}

.popupWrapper .popupClose svg {
  font-size: 60px;
  font-weight: 400;
  color: #ee0968;
}

.popupWrapper .popupFooter button {
  border: none;
  background: #35b736;
  color: #ffffff;
  padding: 16px 35px;
  margin-top: 20px;
  margin-left: 10px;
  font-size: 20px;
  cursor: pointer;
  font-weight: 700;
  letter-spacing: 1.8px;
  height: 56px;
  border-radius: 30px;
}

.popupWrapper .errorMessage {
  color: #ee0968;
}

.popupWrapper::-webkit-scrollbar {
  width: 0.3em;
}

.popupWrapper::-webkit-scrollbar-thumb {
  background-color: #1a5b1b;
  outline: 1px solid slategrey;
}

.traveleListWrapper .traveleListBody {
  background: #ffffff;
  max-width: 800px;
  width: 100%;
  padding: 30px;
  margin: 50px auto;
  box-shadow: 0px 0px 13px 1px #cccccc;
}

@media (max-width: 599px) {
  .traveleListWrapper .traveleListBody {
    box-sizing: border-box;
  }
}

.traveleListWrapper .traveleListBody h1 {
  font-size: 20px !important;
}

.traveleListWrapper .traveleListBody .travelerCardWrapper {
  background: #f4fef0;
  padding: 25px;
  margin: 0 0 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #cccccc;
}

@media (max-width: 599px) {
  .traveleListWrapper .traveleListBody .travelerCardWrapper {
    flex-direction: column;
    box-sizing: border-box;
  }
}

.traveleListWrapper .traveleListBody .travelerCardWrapper:last-child {
  margin: 0 0 0 0;
}

@media (max-width: 599px) {
  .traveleListWrapper .traveleListBody .travelerCardWrapper .cardLeft {
    text-align: center;
  }
}

.traveleListWrapper .traveleListBody .travelerCardWrapper .cardLeft span {
  display: block;
}

.traveleListWrapper
  .traveleListBody
  .travelerCardWrapper
  .cardLeft
  .travelerName {
  font-size: 22px;
  color: #35b736;
  font-weight: 700;
}

.traveleListWrapper
  .traveleListBody
  .travelerCardWrapper
  .cardLeft
  .travelerCity {
  font-size: 18px;
  margin: 10px 0;
  text-transform: uppercase;
  font-weight: 700;
}

.traveleListWrapper
  .traveleListBody
  .travelerCardWrapper
  .cardLeft
  .travelerspace {
  font-size: 18px;
}

.traveleListWrapper
  .traveleListBody
  .travelerCardWrapper
  .cardLeft
  .travelerspace
  i {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  color: #ee0968;
}

.userImage {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
}

.traveleListWrapper
  .traveleListBody
  .travelerCardWrapper
  .cardLeft
  .userImage
  img {
  width: 100%;
}

.traveleListWrapper .traveleListBody .travelerCardWrapper .cardRight {
  text-align: center;
}

.traveleListWrapper .traveleListBody .travelerCardWrapper .cardRight span {
  display: block;
}

.traveleListWrapper
  .traveleListBody
  .travelerCardWrapper
  .cardRight
  .travelerBtnWrapper
  button {
  border: 0;
  background: #35b736;
  color: #ffffff;
  padding: 15px 35px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0px 0px 13px 1px #cccccc;
}

.filterBtnWrapper {
  display: flex;
}
.filterBtnWrapper button {
  border: 0;
  background: #35b736;
  color: #ffffff;
  padding: 5px 20px;
  margin-top: 0px;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.8px;
  cursor: pointer;
  box-shadow: 0px 0px 13px 1px #cccccc;
  margin-left: 5px;
}

.traveleListWrapper
  .traveleListBody
  .travelerCardWrapper
  .cardRight
  .travelerAmount {
  font-size: 45px;
  color: #ee0968;
  font-weight: 400;
  margin-bottom: 10px;
}

.traveleListWrapper .traveleListBody .travelerCardWrapper .cardmiddle {
  max-width: calc(100% - 410px);
  width: 100%;
}

@media (max-width: 599px) {
  .traveleListWrapper .traveleListBody .travelerCardWrapper .cardmiddle {
    max-width: inherit;
    margin-top: 25px;
  }
}

.traveleListWrapper
  .traveleListBody
  .travelerCardWrapper
  .cardmiddle
  .travelerDate {
  font-weight: 700;
  display: flex;
  justify-content: space-between;
}

.traveleListWrapper
  .traveleListBody
  .travelerCardWrapper
  .cardmiddle
  .travelerDate
  i {
  font-style: normal;
  font-size: 14px;
}

.traveleListWrapper
  .traveleListBody
  .travelerCardWrapper
  .cardmiddle
  .travelerLine {
  display: flex;
  background: #35b736;
  height: 3px;
  margin: 15px 0;
  position: relative;
}

.traveleListWrapper
  .traveleListBody
  .travelerCardWrapper
  .cardmiddle
  .travelerLine::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ee0968;
  position: absolute;
  top: -3px;
  left: -4px;
}

.traveleListWrapper
  .traveleListBody
  .travelerCardWrapper
  .cardmiddle
  .travelerLine::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ee0968;
  position: absolute;
  top: -3px;
  right: -4px;
}

.traveleListWrapper
  .traveleListBody
  .travelerCardWrapper
  .cardmiddle
  .travelerDestination {
  font-weight: 700;
  display: flex;
  justify-content: space-between;
}

.traveleListWrapper
  .traveleListBody
  .travelerCardWrapper
  .cardmiddle
  .travelerDestination
  i {
  font-style: normal;
  font-size: 14px;
  max-width: 40%;
}

.traveleListWrapper .traveleListBody textarea {
  width: 100%;
  border: 1px solid #35b736;
  resize: none;
  min-height: 150px;
  padding: 25px;
  font-size: 18px;
  margin: 0 10px;
}

.traveleListWrapper .traveleListBody .btnWrapper {
  margin-top: 20px;
}

.infoCards {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f9fc;
  padding: 40px 0;
}

@media (max-width: 599px) {
  .infoCards {
    flex-direction: column;
    margin: 0 20px;
  }
}

.cardWrapper {
  max-width: 600px;
  width: 100%;
  background: #ffffff;
  margin: 75px 18px 35px 18px;
  padding-bottom: 20px;
  box-shadow: 0px 0px 20px 0px #cccccc;
  background-position: bottom;
  background-size: 100%;
  background-repeat: no-repeat;
}

.cardWrapper .cardImage {
  position: relative;
  height: 150px;
  display: block;
  overflow: hidden;
}

.cardWrapper .cardImage img {
  width: 100%;
}

.cardWrapper .cardImage .cardTitle {
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 25px;
  padding: 10px 40px;
  background: #35b736;
  color: #ffffff;
  text-transform: uppercase;
}

.cardWrapper .cardHeading {
  font-size: 25px;
  font-weight: 600;
  color: #35b736;
  display: flex;
  margin: 8px 0;
  letter-spacing: 0.8px;
  padding: 10px 40px;
}

.cardWrapper .cardBodyText {
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  display: flex;
  letter-spacing: 0.8px;
  padding: 10px 40px;
}

.cardWrapper .cardBtnWrapper {
  display: flex;
  margin: 8px 0;
  padding: 10px 40px;
}

.cardWrapper .cardBtnWrapper button {
  background: transparent;
  border: 0;
  border-radius: 0;
  padding: 0;
  color: #35b736;
  font-size: 15px;
  letter-spacing: 1.3px;
  cursor: pointer;
  text-decoration: underline;
  text-transform: uppercase;
}

.howItWorks {
  background: #ffffff;
  padding: 40px 20px;
}

.howItWorks h1 {
  font-size: 40px;
  font-weight: 400;
  color: #000000;
  letter-spacing: 1.5px;
  text-align: center;
}

.howItWorks p {
  width: 100%;
  max-width: 800px;
  font-size: 20px;
  letter-spacing: 0.8px;
  margin: auto;
  line-height: 35px;
  text-align: center;
}

.howItWorks .howWorksBody {
  display: flex;
  justify-content: center;
  margin: 70px 0 30px;
}

@media (max-width: 599px) {
  .howItWorks .howWorksBody {
    flex-direction: column;
  }
}

.howItWorks .howWorksBody .howWorksLeft {
  max-width: 650px;
  width: 100%;
  float: left;
}

.howItWorks .howWorksBody .howWorksLeft .howWorksSteps {
  display: inline-block;
  border-left: 1px dashed #ee0968;
  position: relative;
  padding-bottom: 30px;
  position: relative;
}

.howItWorks .howWorksBody .howWorksLeft .howWorksSteps:last-child {
  border-left: 0;
}

.howItWorks .howWorksBody .howWorksLeft .howWorksSteps .stepNumer {
  width: 80px;
  height: 80px;
  margin-right: 15px;
  border-radius: 50%;
  background: #ee0968;
  color: #ffffff;
  font-size: 40px;
  font-weight: 700;
  font-style: italic;
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  left: -38px;
  top: -6px;
}

.howItWorks .howWorksBody .howWorksLeft .howWorksSteps .stepText {
  margin-left: 69px;
  float: left;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 35px;
  padding-right: 65px;
  padding-top: 35px;
}

.howItWorks .howWorksBody .howWorksLeft .howWorksSteps .stepText h1 {
  text-align: left;
  color: #35b736;
  font-size: 35px;
  font-weight: 500;
}

.howItWorks .howWorksBody .howWorksRight {
  background-color: #d3dbef;
  max-width: 501px;
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
}

.howItWorks .howWorksBody .howWorksRight::before {
  content: "";
}

@media (max-width: 599px) {
  .howItWorks .howWorksBody .howWorksRight {
    display: none;
  }
}

@media (max-width: 599px) {
  .howItWorks {
    margin: 0 20px;
  }
}

.aboutusWrapper {
  background: #f0f9fc;
  padding: 40px 20px;
}

.aboutusWrapper h1 {
  font-size: 40px;
  font-weight: 400;
  color: #000000;
  letter-spacing: 1.5px;
  text-align: center;
  width: 100%;
}

.aboutusWrapper .aboutCardWrapper {
  max-width: 600px;
  width: 100%;
  background: #ffffff;
  margin: 35px 18px 35px 18px;
  padding: 50px 40px;
  box-shadow: 0px 0px 20px 0px #cccccc;
  background-position: bottom;
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
}

.aboutusWrapper .aboutCardWrapper .aboutCardHeading .aboutCardTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 1.2px;
  color: #35b736;
  text-align: center;
}

.aboutusWrapper .aboutCardWrapper .aboutCardHeading .aboutCardTitle .cardIcon {
  display: flex;
  margin-right: 15px;
  color: #1a5b1b;
  font-size: 40px;
}

.aboutusWrapper .aboutCardWrapper .aboutCardsBody {
  margin: 20px 0;
  font-size: 20px;
  letter-spacing: 0.8px;
  display: block;
  line-height: 32px;
}

@media (max-width: 599px) {
  .aboutusWrapper .aboutCardWrapper {
    box-sizing: border-box;
  }
}

.aboutusWrapper .aboutCardInner {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 599px) {
  .aboutusWrapper .aboutCardInner {
    flex-direction: column;
  }
}

.contactusWrapper {
  background: #ffffff;
  padding: 0 20px 40px 20px;
}

.contactusWrapper h1 {
  font-size: 40px;
  font-weight: 400;
  color: #000000;
  letter-spacing: 1.5px;
  text-align: center;
  width: 100%;
  margin: 0 0 60px 0;
  padding-top: 70px;
}

.contactusWrapper .contactusInner {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  width: 100%;
  margin: auto;
  background: #ffffff;
  flex-direction: column;
  padding: 40px;
  box-shadow: 0px 0px 20px 0px #cccccc;
}

.contactusWrapper .contactusInner .fieldWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.contactusWrapper .contactusInner .fieldWrapper .formFieldsWrapper {
  width: 47%;
  margin: 10px 10px 0;
  height: 64px;
}

@media (max-width: 599px) {
  .contactusWrapper .contactusInner .fieldWrapper .formFieldsWrapper {
    width: 100%;
  }
}

.contactusWrapper .contactusInner .fieldWrapper .btnWrapper {
  margin-top: 15px;
}

@media (max-width: 599px) {
  .contactusWrapper .contactusInner .fieldWrapper {
    flex-direction: column;
  }
}

.contactusWrapper .contactusInner .textareaWrapper {
  width: 100% !important;
}

.contactusWrapper .contactusInner .textareaWrapper .formFieldsWrapper {
  width: 100%;
  margin: 10px 10px 0;
  height: 64px;
}

.contactusWrapper .contactusInner .textareaWrapper .formFieldsWrapper textarea {
  resize: none;
  min-height: 150px;
}

.contactusWrapper .contactusInner form {
  width: 100%;
}

@media (max-width: 599px) {
  .contactusWrapper .contactusInner {
    box-sizing: border-box;
  }
}

.mydeliveriesWrapper,
.traveleListWrapper,
.notFoundWrapper,
.loginWrapper,
.homeWrapper {
  flex-grow: 1;
}

.mydeliveriesWrapper .deliveriesInner {
  background: #ffffff;
  max-width: 800px;
  width: 100%;
  padding: 0 30px 30px;
  margin: 50px auto;
  box-shadow: 0px 0px 13px 1px #cccccc;
}

@media (max-width: 599px) {
  .mydeliveriesWrapper .deliveriesInner {
    box-sizing: border-box;
    margin-top: 100px;
  }
}

.mydeliveriesWrapper .deliveryCardWrapper {
  padding: 25px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cccccc;
}

@media (max-width: 599px) {
  .mydeliveriesWrapper .deliveryCardWrapper {
    flex-direction: column;
    min-height: 80px;
  }
}

.mydeliveriesWrapper .deliveryCardWrapper:last-child {
  margin: 0 0 0 0;
  border: none;
}

@media (max-width: 599px) {
  .mydeliveriesWrapper .deliveryCardWrapper .cardLeft {
    text-align: center;
  }
}

.mydeliveriesWrapper .deliveryCardWrapper .cardLeft span {
  display: block;
}

.mydeliveriesWrapper .deliveryCardWrapper .cardLeft .deliveryName {
  font-size: 22px;
  color: #35b736;
  font-weight: 700;
}

.mydeliveriesWrapper .deliveryCardWrapper .cardLeft .deliveryName span {
  cursor: pointer;
}

.mydeliveriesWrapper .deliveryCardWrapper .cardLeft .deliveryDate {
  font-size: 18px;
  margin: 10px 0;
  text-transform: uppercase;
  font-weight: 700;
}

.mydeliveriesWrapper .deliveryCardWrapper .cardLeft .deliveryEarned {
  font-size: 18px;
}

.mydeliveriesWrapper .deliveryCardWrapper .cardLeft .deliveryEarned i {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  color: #ee0968;
}

@media (max-width: 599px) {
  .mydeliveriesWrapper .deliveryCardWrapper .cardLeft .userImage {
    margin: auto;
  }
}

.mydeliveriesWrapper .deliveryCardWrapper .cardLeft .userImage img {
  width: 100%;
  cursor: pointer;
}

.mydeliveriesWrapper .deliveryCardWrapper .cardRight {
  text-align: center;
}

@media (max-width: 599px) {
  .mydeliveriesWrapper .deliveryCardWrapper .cardRight {
    width: 100%;
  }
}

.mydeliveriesWrapper .deliveryCardWrapper .cardRight span {
  display: flex;
  flex-direction: column;
}

.mydeliveriesWrapper
  .deliveryCardWrapper
  .cardRight
  .travelerBtnWrapper
  button {
  border: 0;
  background: #35b736;
  color: #ffffff;
  padding: 15px 35px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0px 0px 13px 1px #cccccc;
}

.mydeliveriesWrapper .deliveryCardWrapper .cardRight .travelerAmount {
  font-size: 45px;
  color: #ee0968;
  font-weight: 400;
  margin-bottom: 10px;
}

.mydeliveriesWrapper .deliveryCardWrapper .cardmiddle {
  max-width: calc(100% - 410px);
  margin-top: 125px;
  width: 100%;
}

@media (max-width: 599px) {
  .mydeliveriesWrapper .deliveryCardWrapper .cardmiddle {
    max-width: inherit;
    margin-top: 25px;
  }
}

.mydeliveriesWrapper .deliveryCardWrapper .cardmiddle .travelerDate {
  font-weight: 700;
  display: flex;
  justify-content: space-between;
}

.mydeliveriesWrapper .deliveryCardWrapper .cardmiddle .travelerDate i {
  font-style: normal;
  font-size: 14px;
}

.mydeliveriesWrapper .deliveryCardWrapper .cardmiddle .travelerLine {
  display: flex;
  background: #35b736;
  height: 3px;
  margin: 15px 0;
  position: relative;
}

.mydeliveriesWrapper .deliveryCardWrapper .cardmiddle .travelerLine::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ee0968;
  position: absolute;
  top: -3px;
  left: -4px;
}

.mydeliveriesWrapper .deliveryCardWrapper .cardmiddle .travelerLine::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ee0968;
  position: absolute;
  top: -3px;
  right: -4px;
}

.mydeliveriesWrapper .deliveryCardWrapper .cardmiddle .travelerDestination {
  font-weight: 700;
  display: flex;
  justify-content: space-between;
}

.mydeliveriesWrapper .deliveryCardWrapper .cardmiddle .travelerDestination i {
  font-style: normal;
  font-size: 14px;
  max-width: 40%;
}

.detailsInner {
  box-sizing: border-box;
  background: #ffffff;
  max-width: 800px;
  width: 100%;
  padding: 0 30px 30px;
  margin: 50px auto;
  box-shadow: 0px 0px 13px 1px #cccccc;
  margin-top: 100px;
}

.detailsInner h1 {
  margin-top: 25px;
  display: inline-block;
  padding-left: 38px;
  margin-bottom: 50px;
  position: relative;
}

.detailsInner h1 i {
  position: absolute;
  left: -8px;
  top: 3px;
  cursor: pointer;
}

.detailsInner .contsctDetailWrapper {
  /* min-height: 61vh; */
  display: flex;
  justify-content: space-between;
}

@media (max-width: 599px) {
  .detailsInner .contsctDetailWrapper {
    flex-direction: column;
    align-items: center;
  }
}

.addressDetail {
  max-width: 80%;
}

.addressDetail div {
  display: flex;
  margin-bottom: 10px;
}

.addressDetail div span {
  font-style: normal;
  font-weight: 600;
  min-width: 140px;
  max-width: 140px;
}
.addressDetail p {
  padding: 0px;
  margin: 0px;
  word-break: break-word;
}

.detailsInner .contsctDetailWrapper .avatar {
  max-width: 18%;
}

@media (max-width: 599px) {
  .detailsInner .contsctDetailWrapper .avatar {
    width: 100%;
    max-width: inherit;
  }
  .inboxParentWrapper {
    margin-top: 100px;
  }
}

.detailsInner .contsctDetailWrapper .avatar i {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #f8f7f7;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin: auto;
}

.detailsInner .contsctDetailWrapper .avatar i img {
  width: 100%;
}

.detailsInner .contsctDetailWrapper .avatar span {
  display: block;
  text-align: center;
  font-weight: 700;
  margin-top: 10px;
}

.detailsInner .cardmiddle {
  width: 72%;
  margin: 80px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 599px) {
  .detailsInner .cardmiddle {
    max-width: inherit;
    margin-top: 25px;
  }
  .footerWrapper {
    flex-direction: column;
  }
}

.detailsInner .cardmiddle .travelerDate {
  font-weight: 700;
  display: flex;
  justify-content: space-between;
}

.detailsInner .cardmiddle .travelerDate i {
  font-style: normal;
  font-size: 14px;
}

.detailsInner .cardmiddle .travelerLine {
  display: flex;
  background: #35b736;
  height: 3px;
  margin: 15px 0;
  position: relative;
}

.detailsInner .cardmiddle .travelerLine::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ee0968;
  position: absolute;
  top: -3px;
  left: -4px;
}

.detailsInner .cardmiddle .travelerLine::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ee0968;
  position: absolute;
  top: -3px;
  right: -4px;
}

.detailsInner .cardmiddle .travelerDestination {
  font-weight: 700;
  display: flex;
  justify-content: space-between;
}

.detailsInner .cardmiddle .travelerDestination i {
  font-style: normal;
  font-size: 13px;
  font-weight: 400;
  max-width: 40%;
}

.detailsInner .cardmiddle .travelerDestination svg {
  color: #ee0968;
  margin-right: 5px;
}

.detailsInner .cardmiddle .leftLocation {
  font-weight: 600;
  margin-right: 15px;
}

.detailsInner .cardmiddle .rightLocation {
  font-weight: 600;
  margin-left: 15px;
  max-width: 20%;
}

.detailsInner .cardmiddle > div {
  width: 100%;
}

.detailsInner .packageDescription {
  display: flex;
  margin: 35px auto;
  width: 80%;
  flex-direction: column;
}

.detailsInner .packageDescription h2 {
  font-weight: 700;
  margin-bottom: 10px;
}

.detailsInner .packageDescription p {
  margin-top: 13px;
  line-height: 25px;
  letter-spacing: 0.8px;
}

.detailsInner .packageFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.detailsInner .packageFooter svg {
  font-size: 25px;
  margin-left: 10px;
  cursor: pointer;
}

.detailsInner .packageFooter svg path {
  stroke: #1a5b1b;
}

.footerWrapper {
  display: flex;
  position: relative;
  bottom: 0px;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding: 25px;
  background-color: #f3f0f0;
}

.footerWrapper span ul {
  list-style: none;
}

.footerWrapper span ul li {
  display: inline-block;
}

.footerWrapper span ul li a {
  color: inherit;
  padding: 0 8px;
  text-decoration: none;
}

.contsctDetailWrapper {
  /* min-height: 61vh; */
}

@media (min-width: 1800px) {
  .contsctDetailWrapper {
    /* min-height: 74vh; */
  }
}

.contsctDetailWrapper .deliveriesInner {
  padding: 0;
}

.contsctDetailWrapper .account-Tabs {
  display: flex;
  justify-content: space-between;
}

.contsctDetailWrapper .account-Tabs span {
  flex: 1;
  text-align: center;
  padding: 20px 0;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.contsctDetailWrapper .account-Tabs span:hover {
  background: #35b736;
  border-bottom: 3px solid #35b736;
  color: #ffffff;
  font-weight: 400;
}

.contsctDetailWrapper .account-Tabs span.active {
  border-bottom: 3px solid #35b736;
  background: transparent;
  color: #35b736;
  font-weight: 700;
}

.contsctDetailWrapper .tabs-Body {
  padding: 20px 30px;
  display: block;
}

.contsctDetailWrapper .tabs-Body .popupFooter {
  /* display: none; */
}

.contsctDetailWrapper .tabs-Body .popupWrapper {
  max-width: 850px;
}

.contsctDetailWrapper .profileWrapper .profileInner .profileHeader {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 35px;
  padding-bottom: 20px;
  align-items: center;
}

.contsctDetailWrapper .profileWrapper .profileInner .profileHeader .userName {
  font-size: 25px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
}

.contsctDetailWrapper .profileWrapper .profileInner .profileHeader .userName i {
  font-style: normal;
  font-size: 18px;
  font-weight: 400;
}

.contsctDetailWrapper
  .profileWrapper
  .profileInner
  .profileHeader
  .userPicture {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
}

.contsctDetailWrapper
  .profileWrapper
  .profileInner
  .profileHeader
  .userPicture
  img {
  width: 100%;
}

.contsctDetailWrapper
  .profileWrapper
  .profileInner
  .profileHeader
  .userPicture:hover
  .upload-btn-wrapper {
  display: inline-block;
}

.contsctDetailWrapper
  .profileWrapper
  .profileInner
  .profileHeader
  .userPicture
  .upload-btn-wrapper {
  display: none;
  position: absolute;
  overflow: hidden;
  left: 50%;
  margin-left: -9px;
  bottom: 0;
}

.contsctDetailWrapper
  .profileWrapper
  .profileInner
  .profileHeader
  .userPicture
  .btn {
  border: 0;
  color: #cccccc;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.contsctDetailWrapper
  .profileWrapper
  .profileInner
  .profileHeader
  .userPicture
  .upload-btn-wrapper
  input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.contsctDetailWrapper .profileWrapper .profileInner .profileBody ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.contsctDetailWrapper .profileWrapper .profileInner .profileBody ul li {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
}

.contsctDetailWrapper .profileWrapper .profileInner .profileBody ul li svg {
  margin-right: 15px;
  fill: #35b736;
  font-size: 23px;
}

.contsctDetailWrapper .profileWrapper .profileInner .profileFooter {
  border-top: 1px solid #cccccc;
  margin-top: 35px;
  padding-top: 20px;
  display: block;
}

.contsctDetailWrapper .profileWrapper .profileInner .profileFooter ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.contsctDetailWrapper .profileWrapper .profileInner .profileFooter ul li {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
}

.contsctDetailWrapper .profileWrapper .profileInner .profileFooter ul li svg {
  margin-right: 15px;
  fill: #35b736;
  font-size: 23px;
}

.contsctDetailWrapper
  .profileWrapper
  .profileInner
  .profileFooter
  .notVerified
  svg {
  margin-right: 15px;
  fill: #f3f0f0;
  font-size: 23px;
  cursor: default !important;
}

.contsctDetailWrapper .accountInfoWrapper ul {
  list-style: none;
  padding: 0;
}

.contsctDetailWrapper .accountInfoWrapper ul li {
  padding: 16px 0;
  border-bottom: 1px solid #f3f0f0;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.contsctDetailWrapper .accountInfoWrapper ul li:hover {
  background: #f8f7f7;
  padding: 16px;
}

.contsctDetailWrapper .personalInfoWrapper ul {
  padding: 0;
  list-style: none;
}

.contsctDetailWrapper .personalInfoWrapper ul li {
  padding: 18px 18px 0 18px;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 700;
}

.contsctDetailWrapper .personalInfoWrapper ul li i {
  font-style: normal;
  color: #1a5b1b;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  transition: all 0.5s ease-in-out;
}

.contsctDetailWrapper .personalInfoWrapper ul li i .formFieldsWrapper {
  width: 100%;
}

.personalInfoWrapper .formFieldsWrapper input,
.personalInfoWrapper .formFieldsWrapper select {
  padding: 9px 0px 9px 9px;
  margin: 0;
}

.contsctDetailWrapper
  .personalInfoWrapper
  ul
  li
  i
  .formFieldsWrapper
  input:hover {
  background: #f3f0f0 !important;
}

.contsctDetailWrapper .personalInfoWrapper ul li i .btnWrapper {
  margin: 0;
}

.contsctDetailWrapper .personalInfoWrapper ul li i .btnWrapper button {
  margin: 0;
  padding: 5px 16px;
  margin-left: 10px;
  background: transparent;
  color: #35b736;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-decoration: underline;
}

.contsctDetailWrapper .personalInfoWrapper ul li i span.editSaveBtn {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 10px;
}

.contsctDetailWrapper
  .personalInfoWrapper
  ul
  li
  i.normalField
  .formFieldsWrapper
  input,
.normalField .formFieldsWrapper select {
  background: transparent;
  border: 0;
}

body {
  background: #f8f7f7;
  font-family: "Asap", sans-serif;
  height: 100%;
}
html {
  height: 100%;
  overflow-x: hidden;
}
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

body::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

body::-webkit-scrollbar-thumb {
  background-color: #1a5b1b;
  outline: 1px solid slategrey;
}

:focus {
  outline: none;
}

.autocomplete-dropdown-container {
  position: absolute;
  top: 81px;
  z-index: 1;
  width: calc(100% - 2px);
  background-color: #f4fef0;
  border: 1px solid #35b736;
}

.suggestion-item--active,
.suggestion-item {
  padding: 10px 15px;
  cursor: pointer !important;
}

.suggestion-item--active:hover,
.suggestion-item:hover {
  background-color: #b9f7ba;
}

.datePickerField .MuiOutlinedInput-root {
  padding: 0px 15px 8px 7px !important;
  background: #f4fef0 !important;
  border: 1px solid #35b736 !important;
  border-radius: 5px;
}

.datePickerField {
  width: 100% !important;
  margin: 10px 0 !important;
}

.custom_hr {
  height: 12px;
  border-bottom: 1px solid #b5b5b5;
  display: flex;
  justify-content: center;
  margin: 37px 0px;
}

.custom_hr > div {
  background-color: white;
  height: fit-content;
  padding: 0 10px;
  color: #6b6b6b;
  font-size: 19px;
}

.Toastify__toast--success {
  border-left: 6px solid #07bc0c;
}
.Toastify__toast--error {
  border-left: 6px solid #e74c3c;
}
.Toastify__toast--info {
  border-left: 6px solid #3498db;
}
.Toastify__close-button {
  align-self: center !important;
}

.notificationsWrapper {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #ffffff; /* If you want dots under the hoverable text */
}
.notificationsWrapper img {
  height: 40px;
  cursor: pointer;
  /* margin-bottom: -13px; */
}
.notificationsWrapper .notificationsBody {
  color: black;
  visibility: hidden;
  width: 291px;
  background-color: #ffffff;
  text-align: center;
  padding: 5px 0;
  right: 0;
  margin-top: 15px;
  border-radius: 6px;
  box-shadow: 0 0 13px 1px #ccc;
  position: absolute;
  z-index: 1;
}
.notificationsBody::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  right: 13px;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #ffffff transparent;
}
.showNotificationsBody {
  visibility: visible !important;
}
.notificationsBody .header {
  font-size: 20px;
  padding: 10px;
  display: block;
  border-bottom: 1px solid #e5e5e5;
}
.notificationsBody .header span {
  width: fit-content;
}
.needOpening {
  display: inline;
  font-size: 0.5px;
  border-style: solid;
  border-radius: 50%;
  vertical-align: top;
  border-color: #28a745;
}
.notificationsBody .notificationsItem {
  text-align: left;
  cursor: pointer;
  padding-left: 3px;
}
.newNotification {
  background-color: #f4f8fa;
  padding-left: 0px;
  border-left: 3px solid #418cf6;
}
.notificationsItem:hover {
  background-color: #e1e8eb;
}
.notificationsItem > div {
  border-bottom: 1px solid #e5e5e5;
  padding: 15px 0px;
  margin: 0px 20px;
  display: flex;
}
.notificationsBody .itemImg {
  width: 77px;
  height: auto;
  align-self: center;
  margin-right: 10px;
  margin-left: -8px;
  border-radius: 50%;
}
.notificationsBody .itemBody {
  flex-grow: 1;
}
.notificationsBody .itemHeader {
}
.notificationsBody .itemHeader p {
  margin: 0px;
  padding: 0px;
  padding-bottom: 1px;
}
.notificationsBody .itemHeader span {
  float: right;
  font-size: 12px;
  color: #777777;
}
.notificationsBody .info {
  margin-bottom: 10px;
  font-size: 14px;
  width: auto;
  color: #777777;
}
.notificationsBody .rout {
  border-left: 3px solid #297df6;
  padding-left: 5px;
  color: #297df6;
  font-size: 13px;
}
.viewAllNotificationBtn {
  padding: 10px;
  background-color: #28a745;
  margin-bottom: -5px;
  box-shadow: 0 0 13px 1px #ccc;
  border-radius: 0 0 3px 3px;
  color: white;
}

.notificationDetailsWraper {
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: 0px 0px 4px 1px #cccccc;
  padding: 40px 80px !important;
  color: #5a5959;
}

.notificationDetailsWraper .heading {
  padding: 0px;
  margin: 0px;
}
.notificationDetailsWraper > div {
  padding: 40px 0px;
}

.horizFlexDiv {
  display: flex;
  flex-direction: row;
}

.travelDetails {
  margin-right: 60px;
  flex-grow: 1;
  /* background-color: cornsilk; */
}

.travelKeyField > span {
  font-size: 16px;
  color: #a3a2a2;
}

.travelKeyField > p {
  font-weight: bold;
  padding: 0px;
  margin: 0px;
  font-size: 20px;
}

.notificationDetailsWraper > .horizFlexDiv:not(:last-of-type) {
  border-bottom: 1px solid #cccccc;
}

.notificaitonImgDiv {
  min-width: 100px;
  max-width: 100px;
  min-height: 100px;
  /* padding-right: 20px; */
  /* background-color: burlywood; */
}
.notificaitonImgDiv img {
  height: 85px;
}

.travelRoutInfoWraper {
  margin-top: 30px;
}

.travelRoutInfoWraper .pick_n_dropLocInfoWraper {
  display: flex;
  justify-content: space-between;
}

.pickDropLocVisual {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  background-color: #d3d2d2;
  height: 5px;
}
.pickDropLocVisual img {
  margin-top: -12px;
  height: 27px;
}

.requestDetailsWraper {
  flex-grow: 1;
}

.requestDetailsWraper div {
  color: #838282;
  margin-right: 10%;
}

.requestDetailsWraper h3 {
  display: inline;
}
.requesterDateTime {
  float: right;
  color: #a3a2a2;
  font-size: 14px;
}
.requestDetailsWraper img {
  margin-right: 5px;
  height: 12px;
}

.requestDetailsActionWraper {
  display: flex;
  justify-content: end;
  margin-right: 0px !important;
}

.requestDetailsAcceptBtn {
  background-color: #52c153;
  font-size: 16px;
  color: white !important;
  margin: 10px !important;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}
.requestDetailsRejectBtn {
  margin: 10px !important;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid black;
  color: #605f5f;
  cursor: pointer;
}

.header_dropMenuItem {
  color: #272727 !important;
  font: 18px/21px Roboto !important;
  width: 243px;
  font-weight: 500 !important;
  padding: 6px 25px !important;
}

.header_userName {
  color: #35b736 !important;
}

#account-menu .MuiPaper-root {
  right: 32px !important;
}

.forgetPassword {
  float: right;
}

/* auto generate code from xd for travel steps */

#n_21 {
  left: 26%;
  top: 15px;
  height: 80px;
  position: relative;
  width: 217px;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  color: rgba(39, 39, 39, 1);
}
#button {
  position: relative;
  width: 180px;
  height: 100px;
  left: 250px;
  top: 100px;
  overflow: visible;
  padding: 16px 48px 16px 0px;
}

#Continue {
  left: 50px;
  top: 10px;
  position: relative;
  width: 85px;
  white-space: nowrap;
  line-height: 0px;
  margin-top: 0px;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 0.25px;
}
#add_icon {
  fill: rgba(54, 183, 54, 1);
}
.add_icon {
  overflow: visible;
  position: relative;
  width: 50px;
  height: 50px;
  left: 74%;
  top: -150px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  cursor: pointer;
}
#remove_icon {
  fill: rgba(54, 183, 54, 1);
}
.remove_icon {
  overflow: visible;
  position: relative;
  width: 50px;
  height: 50px;
  left: 4%;
  top: -150px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  cursor: pointer;
}

.add_travel_center {
  width: 60%;
  align-items: center;
  margin-left: 20%;
}

@media (max-width: 599px) {
  .add_travel_center {
    width: 100%;
    margin-left: 0;
  }

  #n_21 {
    left: 0;
    width: auto;
    top: 20px;
    font-size: 30px;
  }
}

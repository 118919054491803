.popupBoxWrapper {
  background-color: white;
  min-height: calc(100% - 200px);
  font: 20px/24px Roboto;
  font-weight: 500;
  letter-spacing: 0px;
  color: #272727;
}
.popupBoxWrapper > div {
  display: flex;
  justify-content: center;
}
.popupBox {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 25px #00000026;
  margin: 30px 0;
  width: 430px;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}
.popupBox a {
  font-size: 14px;
}
.popupCloseIcon {
  color: #ee0968;
  align-self: flex-end;
  font-size: 30px;
  cursor: pointer;
}

.popupTitle {
  margin-top: 25px;
  font: normal normal bold 30px/32px Roboto;
  letter-spacing: 0.8px;
  margin-bottom: 25px;
  color: #35b736;
}
.popupDescription {
  letter-spacing: 0px;
  font-size: 14px;
  color: #7e7e7e;
}
.popupPhoneNumberInput > div {
  background: #f4fef0 0% 0% no-repeat padding-box;
  border-bottom: none !important;
  border: 1px solid #35b736 !important;
  border-radius: 4px;
  padding: 15px 15px 10px !important;
}
.popupPhoneNumberInput > input {
}
.popupPhoneNumberInput > div::after,
.popupPhoneNumberInput > div::before {
  display: none;
}
.continueButton {
  font: normal normal medium 24px/33px Roboto;
  letter-spacing: 0.6px;
  color: #ffffff;
  background-color: #35b736;
  box-shadow: 2px 3px 6px #35b7369c;
  padding: 10px 30px;
  border-radius: 20px;
  width: fit-content;
  margin: 30px 0;
  cursor: pointer;
}
.continueButtonDisabled {
  background-color: #c6c6c6;
  box-shadow: 2px 3px 6px #c6c6c6;
  cursor: default;
}

.resendCodeBtn {
  text-decoration: underline;
  font: normal normal medium 18px/35px Roboto;
  letter-spacing: 0px;
  font-size: 14px;
  cursor: pointer;
  color: #3682f5;
}
.inlineSpaceBetween {
  display: flex;
  justify-content: space-between;
}
.OTP-inputs {
  display: flex;
  justify-content: space-between;
}
.OTP-inputs label {
  padding: 21px 18px 9px;
  min-width: 20px;
  min-height: 30px;
  background: #f4fef0 0% 0% no-repeat padding-box;
  border: 2px solid #35b736;
  border-radius: 4px;
  font-size: 30px;
}

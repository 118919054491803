/* Inbox Style Starts here */
.inboxParentWrapper {
  background-color: white;
  justify-content: center;
  flex-direction: row;
  display: flex;
  min-height: calc(100% - 200px);
  font: 20px/24px Roboto;
  font-weight: 500;
  letter-spacing: 0px;
  color: #272727;
}

.inboxWrapper {
  display: flex;
  justify-content: start;
  flex-direction: column;
  width: 650px;
  background-color: white;
  overflow-y: auto;
}

.inboxTitle {
  text-align: center;
  font: normal normal bold 32px/39px Roboto;
  letter-spacing: 1.05px;
  color: #35b736;
  opacity: 1;
  margin: 40px;
}
.chatListCard {
  display: flex;
  flex-direction: column;
}
.chatCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 30px 20px 10px;
  cursor: pointer;
}
.chatCard:hover {
  background-color: #7e7e7e26;
}
.avatar {
  width: 80px;
  height: 70px;
}
.travelDirection {
  display: flex;
}
.direction {
  align-self: center;
  width: 20px;
  height: 10px;
  padding: 0 10px;
}

.alignedFlexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chatTime {
  margin-right: 30px;
  color: #ee0968;
}
/* Arrow style starts */
.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
/* Arrow style ends */

/* Chat style  start here */
.chatTopHeader {
  margin-top: 20px;
  text-align: left;
  font: 20px/24px Roboto;
  font-weight: 500;
  letter-spacing: 0px;
  color: #272727;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 20px 10px;
  cursor: pointer;
}
.chatTopHeader:hover {
  background-color: #ededed;
}
.headerChatTime {
  text-align: left;
  font: 18px/21px Roboto;
  letter-spacing: 0px;
  font-weight: 500;
  color: #7e7e7e;
}
.chatBody {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.chatInput-footer {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.sendMessage {
  border-radius: 50%;
  height: 65px;
  width: 65px;
  margin-left: -65px;
  background-color: #35b736;
  cursor: pointer;
}
.chatInput-footer input {
  font-size: 20px;
  padding: 20px;
  flex-grow: 1;
  background: #f4fef0 0% 0% no-repeat padding-box;
  border: 1px solid #35b736;
  border-radius: 36px;
}
.info {
  width: 30px;
  margin-right: 20px;
}

.newChatDate {
  font: 16px/19px Roboto;
  letter-spacing: 0px;
  font-weight: 500;
  color: #7e7e7e;
  text-align: center;
  margin: 30px;
}
.messageCard {
  display: flex;
  max-width: 400px;
  margin-bottom: 3px;
}
.messageWrapper {
  flex-direction: column;
  text-align: right;
}
.sender {
  font: 16px/19px Roboto;
  letter-spacing: 0px;
  color: #7e7e7e;
}

.messageBody {
  display: flex;
  justify-content: end;
}

.messageBody > div {
  font: 18px/21px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  background-color: #35b736;
  padding: 20px;
  text-align: left;
}
.messageTime {
  margin-top: 5px;
  font: 14px/16px Roboto;
  letter-spacing: 0px;
  color: #7e7e7e;
}
.sent .messageBody > div {
  border-radius: 20px 20px 0;
}
.recieved {
  align-self: start;
}
.recieved .messageBody {
  justify-content: start;
}
.recieved .messageCard {
  flex-direction: row-reverse;
}
.recieved .messageWrapper {
  flex-direction: row-reverse;
  text-align: start;
}
.recieved .messageBody > div {
  border-radius: 20px 20px 20px 0;
  background: #ededed 0% 0% no-repeat padding-box;
  color: #272727;
}
.sent {
  align-self: end;
}

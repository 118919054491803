body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.defaultAvatar {
  background-color: #35b736;
  font-size: 52px;
  color: #fff;
  font-family: Roboto;
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  height: inherit;
  width: inherit;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
